import React, { useState } from 'react';
import './Footer.css'; // Include your CSS file



const Footer = () => {
  return (
    <div class="et_builder_inner_content et_pb_gutters3">
      <div class="et_pb_section et_pb_section_0_tb_footer et_section_regular">
			  <span class="et_pb_background_pattern"></span>
				  <div class="et_pb_row1 et_pb_row_0_tb_footer et_pb_row_1-2_1-4_1-4">
				    <div class="et_pb_column et_pb_column_1_22 et_pb_column_0_tb_footer  et_pb_css_mix_blend_mode_passthrough">
				      <div class="et_pb_module et_pb_text et_pb_text_0_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                <div class="et_pb_text_inner"><h2>Find My Trainer</h2></div>
              </div>
              <div class="et_pb_module et_pb_text et_pb_text_1_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
				        <div class="et_pb_text_inner">
                  <div class="flex max-w-full flex-col flex-grow">
                    <div data-message-author-role="assistant" data-message-id="0baa751d-7f61-4cc0-858c-5da45587f395" dir="auto" class="min-h-[20px] text-message flex w-full flex-col items-end gap-2 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto">
                      <div class="flex w-full flex-col gap-1 empty:hidden first:pt-[3px]">
                        <div class="markdown prose w-full break-words dark:prose-invert dark">
                          <p class="footer_p">Your trusted platform for finding certified personal trainers to help you achieve your fitness goals. <strong>Discover the perfect trainer and start your journey today!</strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
			        </div>
              <div class="et_pb_module et_pb_blurb et_pb_blurb_0_tb_footer  et_pb_text_align_left  et_pb_blurb_position_left et_pb_bg_layout_light">
				        <div class="et_pb_blurb_content22">
					        <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><span class="et-waypoint et_pb_animation_top et_pb_animation_top_tablet et_pb_animation_top_phone et-pb-icon et-animated"><img fetchpriority="high" decoding="async" width="343" height="343" src="/images/email.png" alt=""  sizes="(max-width: 343px) 100vw, 343px" class="et-waypoint et_pb_animation_top et_pb_animation_top_tablet et_pb_animation_top_phone wp-image-38 et-animated"/></span></span></div>
					          <div class="et_pb_blurb_container">
					            <div class="et_pb_blurb_description"><p><a href="mailto:support@findmytrainer.co.zw">support@findmytrainer.co.zw</a></p></div>
					          </div>
				          </div>
                        </div>
			        </div>
              <div class="et_pb_column et_pb_column_1_42 et_pb_column_1_tb_footer  et_pb_css_mix_blend_mode_passthrough">
				        <div class="et_pb_module et_pb_heading et_pb_heading_0_tb_footer et_pb_bg_layout_">
				          <div class="et_pb_heading_container"><h1 class="et_pb_module_heading">Important Links</h1></div>
			          </div>
                <div class="et_pb_module et_pb_text et_pb_text_2_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
				          <div class="et_pb_text_inner"><p class="footer_text"><a href="#">Privacy Policy</a></p></div>
			          </div>
                <div class="et_pb_module et_pb_text et_pb_text_3_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
			            <div class="et_pb_text_inner"><p class="footer_text"><a href="#">Terms Of Service</a></p></div>
			          </div>
                <div class="et_pb_module et_pb_text et_pb_text_4_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
			            <div class="et_pb_text_inner"><p class="footer_text"><a href="https://appertax.co.zw/findmytrainer/contact/">Contact Us</a></p></div>
			          </div>
                <div class="et_pb_module et_pb_text et_pb_text_5_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
				          <div class="et_pb_text_inner"><p class="footer_text"><a href="/browse">Browse Trainers</a></p></div>
			          </div>
                <div class="et_pb_module et_pb_text et_pb_text_6_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
				          <div class="et_pb_text_inner"><p class="footer_text"><a href="/trainer-register">Register As Trainer</a></p></div>
			          </div>
			        </div>
              <div class="et_pb_column et_pb_column_1_4 et_pb_column_2_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">
				        <div class="et_pb_module et_pb_heading et_pb_heading_1_tb_footer et_pb_bg_layout_">
				          <div class="et_pb_heading_container"><h1 class="et_pb_module_heading">Follow Us</h1></div>
			          </div>
                      <ul class="et_pb_module et_pb_social_media_follow et_pb_social_media_follow_0_tb_footer clearfix et_pb_bg_layout_light">
  <li class="et_pb_social_media_follow_network_0_tb_footer et_pb_social_icon et_pb_social_network_link et-social-facebook">
    <a href="#" class="icon et_pb_with_border" title="Follow on Facebook" target="_blank">
      <img src="/images/facebook.png" alt="Follow on Facebook" class="social-icon"/>
    </a>
  </li>
  <li class="et_pb_social_media_follow_network_1_tb_footer et_pb_social_icon et_pb_social_network_link et-social-twitter">
    <a href="#" class="icon et_pb_with_border" title="Follow on Instagram" target="_blank">
      <img src="/images/instagram.png" alt="Follow on Instagram" class="social-icon"/>
    </a>
  </li>
  <li class="et_pb_social_media_follow_network_2_tb_footer et_pb_social_icon et_pb_social_network_link et-social-instagram">
    <a href="#" class="icon et_pb_with_border" title="Follow on X" target="_blank">
      <img src="/images/twitter.png" alt="Follow on X" class="social-icon"/>
    </a>
  </li>
</ul>

			        </div>
			      </div>
            <div class="et_pb_with_border et_pb_row112 et_pb_row_1_tb_footer">
				      <div class="et_pb_column et_pb_column_4_4 et_pb_column_3_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">
		            <div class="et_pb_module et_pb_text et_pb_text_7_tb_footer  et_pb_text_align_center et_pb_bg_layout_light">
		              <div class="et_pb_text_inner_footer">Copyright © 2024 Find My Trainer. All Rights Reserved.</div>
			          </div>
			        </div>
				    </div>
			    </div>		
        </div>
  );
};

export default Footer;
