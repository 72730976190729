import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './TrainerProfile.css'; // Ensure you create and style this CSS file
import Header from './Header';
import Footer from './Footer';

const TrainerProfile = () => {
  const { id } = useParams(); // Get the trainer ID from the URL
  const [trainer, setTrainer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Form state
  const [fullName, setFullName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [howDidYouHear, setHowDidYouHear] = useState('');
  const [coachingType, setCoachingType] = useState('');
  const [fitnessGoal, setFitnessGoal] = useState('');
  const [trainingLocation, setTrainingLocation] = useState('');
  const [sessionsPerWeek, setSessionsPerWeek] = useState('');
  const [reason, setReason] = useState('');
  const [availability, setAvailability] = useState('');
  const [comments, setComments] = useState('');

  useEffect(() => {
    const fetchTrainer = async () => {
      try {
        // Replace with your API endpoint
        const response = await fetch(`https://server.appertaxtech.co.zw/api/trainers/${id}`);
        if (!response.ok) throw new Error('Trainer not found');
        const data = await response.json();
        setTrainer(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTrainer();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      trainerId: id,
      fullName,
      userEmail,
      contactNumber,
      howDidYouHear,
      coachingType,
      fitnessGoal,
      trainingLocation,
      sessionsPerWeek,
      reason,
      availability,
      comments,
    };

    try {
      const response = await fetch('http://localhost:5000/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error('Failed to send enquiry');
      alert('Your enquiry has been sent successfully.');
      // Clear form fields
      setFullName('');
      setUserEmail('');
      setContactNumber('');
      setHowDidYouHear('');
      setCoachingType('');
      setFitnessGoal('');
      setTrainingLocation('');
      setSessionsPerWeek('');
      setReason('');
      setAvailability('');
      setComments('');
    } catch (error) {
      alert(error.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!trainer) return <div>No trainer found</div>;

  return (
    <div>
      <Header/>

      <div className="trainer-profile">
        <div className="trainer-profile-content">
          <div className="trainer-profile-info-grid">
            <div className="trainer-page-left-info">
              <img
                alt={`Profile picture of ${trainer.first_name}`}
                loading="eager"
                src={`http://localhost:5000/uploads/${trainer.profile_photo}`}
                className="trainer-page-image"
              />
            </div>
            <div className="trainer-overview-text-wrapper">
              <div className="trainer-info-wrapper">
                <h2 className="trainer-profile-name-heading">{trainer.first_name} {trainer.last_name}</h2>
              </div>
              <div className="trainer-info-wrapper">
                <h3 className="profile-info-heading">Experience</h3>
                <div className="trainer-overview-text">{trainer.experience_years}</div>
              </div>
              <div className="trainer-info-wrapper">
                <h3 className="profile-info-heading">Location</h3>
                <div className="trainer-overview-text">{trainer.location}</div>
              </div>
              <div className="trainer-info-wrapper">
                <h3 className="profile-info-heading">Online Coaching</h3>
                <div className="trainer-overview-text">{trainer.onlineCoaching ? 'Yes' : 'No'}</div>
              </div>
              <div className="trainer-info-wrapper">
                <h3 className="profile-info-heading">Rate (per session)</h3>
                <div className="rate">
                  <div className="trainer-overview-text">${trainer.charge_per_session}</div>
                </div>
              </div>
              <div className="trainer-info-wrapper">
                <h3 className="profile-info-heading">Block Booking</h3>
                <div className="trainer-overview-text">${trainer.charge_per_block} for {trainer.sessions_per_block} blocks</div>
              </div>
            </div>
          </div>
          <div className="trainer-about-section-wrapper">
            <h3 className="trainer-info-page-heading about">About</h3>
            <div className="trainer-about-text">
              <p>{trainer.about}</p>
            </div>
          </div>
          <div className="profile-form">
            <form className="client-enquiry-form" onSubmit={handleSubmit}>
              <div className="client-enquiry-heading-block">
                <h3 className="profile-enquiry-form-heading">Contact {trainer.first_name} {trainer.last_name}</h3>
              </div>
              <div className="enquiry-form-section">
                <div className="submit-enquiry-contact-wrapper">
                  <h4 className="enquiry-form-field-heading">Full Name</h4>
                  <input
                    className="mailing-list-form-input enquiry"
                    type="text"
                    placeholder="Enter name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                  />
                </div>
                <div className="submit-enquiry-contact-wrapper">
                  <h4 className="enquiry-form-field-heading">Email</h4>
                  <input
                    className="mailing-list-form-input"
                    type="email"
                    placeholder="Enter email"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="submit-enquiry-contact-wrapper">
                  <h4 className="enquiry-form-field-heading">Contact Number</h4>
                  <input
                    className="mailing-list-form-input enquiry"
                    type="tel"
                    placeholder="Enter number"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="enquiry-form-section">
                <div className="divider"></div>
              </div>
              <div className="enquiry-form-section_1">
                <h4 className="enquiry-form-field-heading">How did you hear about us?</h4>
                <select
                  className="mailing-list-form-input enquiry"
                  value={howDidYouHear}
                  onChange={(e) => setHowDidYouHear(e.target.value)}
                  required
                >
                  <option value="">Select one...</option>
                  <option value="Search engine">Search engine</option>
                  <option value="Friend/family/colleague">Friend/family/colleague</option>
                  <option value="Social media">Social media</option>
                  <option value="Ads">Ads</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="enquiry-form-section_1">
                <h4 className="enquiry-form-field-heading">What type of coaching are you looking for?</h4>
                <select
                  className="mailing-list-form-input enquiry"
                  value={coachingType}
                  onChange={(e) => setCoachingType(e.target.value)}
                  required
                >
                  <option value="">Select one...</option>
                  <option value="1-1">1-1</option>
                  <option value="2-1">2-1</option>
                  <option value="Group">Group</option>
                </select>
              </div>
              <div className="enquiry-form-section_1">
                <h4 className="enquiry-form-field-heading">What is your main fitness goal?</h4>
                <select
                  className="mailing-list-form-input enquiry"
                  value={fitnessGoal}
                  onChange={(e) => setFitnessGoal(e.target.value)}
                  required
                >
                  <option value="">Select one...</option>
                  <option value="Get fitter">Get fitter</option>
                  <option value="Lose weight">Lose weight</option>
                  <option value="Be healthier">Be healthier</option>
                  <option value="Get stronger">Get stronger</option>
                  <option value="Get toned">Get toned</option>
                  <option value="Recovery">Recovery</option>
                  <option value="Prepare for event">Prepare for event</option>
                </select>
              </div>
              <div className="enquiry-form-section_1">
                <h4 className="enquiry-form-field-heading">Where do you want to train?</h4>
                <select
                  className="mailing-list-form-input enquiry"
                  value={trainingLocation}
                  onChange={(e) => setTrainingLocation(e.target.value)}
                  required
                >
                  <option value="">Select one...</option>
                  <option value="Gym">At a gym</option>
                  <option value="Online">Online</option>
                  <option value="Home">At home</option>
                  <option value="Outdoors">Outdoors</option>
                </select>
              </div>
              <div className="enquiry-form-section_1">
                <h4 className="enquiry-form-field-heading">How many times a week do you want to train?</h4>
                <select
                  className="mailing-list-form-input enquiry"
                  value={sessionsPerWeek}
                  onChange={(e) => setSessionsPerWeek(e.target.value)}
                  required
                >
                  <option value="">Select one...</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <div className="enquiry-form-section_1">
                <h4 className="enquiry-form-field-heading">Why have you decided to look for a personal trainer?</h4>
                <textarea
                  className="enquiry-text-area"
                  placeholder="What has made you reach out now?"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="enquiry-form-section_1">
                <h4 className="enquiry-form-field-heading">What is your availability?</h4>
                <textarea
                  className="enquiry-text-area"
                  placeholder="What days and times are you able to train?"
                  value={availability}
                  onChange={(e) => setAvailability(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="enquiry-form-section_1">
                <h4 className="enquiry-form-field-heading">Any additional questions or comments?</h4>
                <textarea
                  className="enquiry-text-area"
                  placeholder="Any questions or information you would like to share."
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                ></textarea>
              </div>
              <div className="centre">
                <input type="submit" className="btn" value="Submit Enquiry" />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default TrainerProfile;
