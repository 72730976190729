import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditTrainerModal = ({ trainer, onClose, onUpdate }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    experience: '',
    location: '',
    online_coaching: false,
    rate_per_session: '',
    block_booking: '',
    profile_image: ''
  });

  useEffect(() => {
    setFormData(trainer);
  }, [trainer]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://server.appertaxtech.co.zw/api/admin/trainers/${trainer.id}`, formData);
      onUpdate();
      onClose();
    } catch (error) {
      console.error('Error updating trainer:', error);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Edit Trainer</h2>
        <form onSubmit={handleSubmit}>
          <label>
            First Name:
            <input
              type="text"
              name="first_name"
              value={formData.first_name || ''}
              onChange={handleChange}
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              name="last_name"
              value={formData.last_name || ''}
              onChange={handleChange}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email || ''}
              onChange={handleChange}
            />
          </label>
          <label>
            Experience:
            <input
              type="text"
              name="experience"
              value={formData.experience || ''}
              onChange={handleChange}
            />
          </label>
          <label>
            Location:
            <input
              type="text"
              name="location"
              value={formData.location || ''}
              onChange={handleChange}
            />
          </label>
          <label>
            Online Coaching:
            <input
              type="checkbox"
              name="online_coaching"
              checked={formData.online_coaching || false}
              onChange={handleChange}
            />
          </label>
          <label>
            Rate per Session:
            <input
              type="text"
              name="rate_per_session"
              value={formData.rate_per_session || ''}
              onChange={handleChange}
            />
          </label>
          <label>
            Block Booking:
            <input
              type="text"
              name="block_booking"
              value={formData.block_booking || ''}
              onChange={handleChange}
            />
          </label>
          <label>
            Profile Image URL:
            <input
              type="text"
              name="profile_image"
              value={formData.profile_image || ''}
              onChange={handleChange}
            />
          </label>
          <button type="submit">Save Changes</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
};

export default EditTrainerModal;
