import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import TrainerRegister from './pages/TrainerRegister';
import AdminPanel from './pages/AdminPanel';
import PostRegistrationPage from './components/PostRegistrationPage'; // Import your new page
import CreateApplicationForm from './components/CreateApplicationForm';
import BrowseTrainers from './pages/BrowseTrainers';
import TrainerProfile from './components/TrainerProfile'; // Import TrainerProfile
import TrainerLogin from './components/TrainerLogin';
import TrainerDashboard from './components/TrainerDashboard';
import About from './pages/About'
import ApplicationSuccess from './components/ApplicationSuccess';
import Read from './pages/Read.js';
import Edit from './pages/Edit.js';

function App() {
  return (
    <div>
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/trainer-register" element={<TrainerRegister />} />
        <Route path="/admin-panel" element={<AdminPanel />} />
        <Route path="/post-registration/:trainerId" element={<PostRegistrationPage />} />
        <Route path="/create-application" element={<CreateApplicationForm />} />
        <Route path="/browse" element={<BrowseTrainers />} /> 
        <Route path="/trainers/:id" element={<TrainerProfile />} /> {/* Route for TrainerProfile */}
        <Route path="/trainer-login" element={<TrainerLogin />} />
        <Route path="/trainer-dashboard" element={<TrainerDashboard />} />
        <Route path="/about" element={<About />} />
        <Route path="/application-success" element={<ApplicationSuccess />} />
        <Route path="/read/:id" element={<Read />} />
        <Route path="/edit/:id" element={<Edit />} />
      </Routes>
    </div>
  );
}

export default App;
