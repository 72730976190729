import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import './Read.css'
import Header from "../components/Header";
import Footer from "../components/Footer";

function Read() {
  const [data, setData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`https://server.appertaxtech.co.zw/get_trainer/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);
  return (
    <div className="read">
      <Header />
    <div className="container-fluid vw-100 vh-100 bg-primary">
      
      <h1 className="trainer_id">Trainer {id} </h1>
      <Link to="/admin-panel" className="btn100 btn-success1">Back</Link>
      {data.map((trainer) => {
        return (
          <ul className="list-group">
            <li className="list-group-item">
              <b>ID: </b>
              {trainer["id"]}
            </li>
            <li className="list-group-item">
              <b>Fisrt Name: </b>
              {trainer["first_name"]}
            </li>
            <li className="list-group-item">
              <b>Last Name: </b>
              {trainer["last_name"]}
            </li>
            <li className="list-group-item">
              <b>Email: </b>
              {trainer["email"]}
            </li>
            <li className="list-group-item">
              <b>Contact Number: </b>
              {trainer["contact_number"]}
            </li>
            <li className="list-group-item">
              <b>Gender: </b>
              {trainer["sex"]}
            </li>
            <li className="list-group-item">
              <b>Date Of Birth: </b>
              {trainer["date_of_birth"]}
            </li>
            <li className="list-group-item">
              <b>Location: </b>
              {trainer["location"]}
            </li>
            <li className="list-group-item">
              <b>Gym Address: </b>
              {trainer["gym_address"]}
            </li>
            <li className="list-group-item">
              <b>Years of Experience: </b>
              {trainer["experience_years"]}
            </li>
            <li className="list-group-item">
              <b>Charge Per Session: </b>
              {trainer["charge_per_session"]}
            </li>
            <li className="list-group-item">
              <b>Charge Per Block: </b>
              {trainer["charge_per_block"]}
            </li>
            <li className="list-group-item">
              <b>Sessions Per Block: </b>
              {trainer["sessions_per_block"]}
            </li>
            <li className="list-group-item">
              <b>Online Coaching: </b>
              {trainer["online_coaching"]}
            </li>
            <li className="list-group-item">
              <b>Specialization: </b>
              {trainer["expertise"]}
            </li>
            <li className="list-group-item">
              <b>Where they heard about us: </b>
              {trainer["hear_about"]}
            </li>
            <li className="list-group-item">
              <b>Instagram Handle: </b>
              {trainer["instagram_handle"]}
            </li>
            <li className="list-group-item">
              <b>About Trainer: </b>
              {trainer["about"]}
            </li>
            <li className="list-group-item">
              <b>Profile Photo: </b>
              {trainer["profile_photo"]}
            </li>
            <li className="list-group-item">
              <b>Approved Status: </b>
              {trainer["approved"]}
            </li>
          </ul>
        );
      })}
      
    </div>

    <Footer />
    </div>
  );
}

export default Read;
