import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CreateApplicationForm.css';
import Header from './Header';
import Footer from './Footer';

const CreateApplicationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    hearAbout: '',
    sex: '',
    dateOfBirth: '',
    contactNumber: '',
    location: '',
    gymAddress: '',
    experienceYears: '',
    chargePerSession: '',
    chargePerBlock: '',
    sessionsPerBlock: '',
    onlineCoaching: '',
    expertise: [],
    instagramHandle: '',
    about: '',
    profilePhoto: null,
  });

  const [message, setMessage] = useState('');

  const expertiseOptions = [
    'Strength Training',
    'Cardio',
    'Yoga',
    'Pilates',
    'CrossFit',
    'HIIT',
    'Bodybuilding',
    'Nutrition',
  ];

  const experienceOptions = [
    'Less than 1 year',
    'Between 1-3 years',
    'Between 3-5 years',
    'Between 5-10 years',
    'More than 10 years'
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (checked) {
        setFormData({
          ...formData,
          expertise: [...formData.expertise, value],
        });
      } else {
        setFormData({
          ...formData,
          expertise: formData.expertise.filter((item) => item !== value),
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      profilePhoto: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      if (key === 'profilePhoto') {
        if (formData[key]) {
          formDataToSend.append(key, formData[key]);
        }
      } else if (key === 'expertise') {
        formData[key].forEach((item) => formDataToSend.append('expertise[]', item));
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });
  
    try {
      const response = await axios.post('https://server.appertaxtech.co.zw/api/trainers/update', formDataToSend, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setMessage(response.data.message);
      setTimeout(() => {
        navigate('/application-success'); // Redirect to a success page after form submission
      }, 2000);
    } catch (error) {
      console.error('Error submitting application:', error);
      setMessage('Error submitting application. Please try again.');
    }
  };  

  return (
    <div className="create-application-form">

        <Header/>

      <form className="application-form" onSubmit={handleSubmit}>
        <h1>Create Application</h1>

        {message && <div className="form-message">{message}</div>}

        <div className="form-group">
          <label htmlFor="email">Please enter your email address. *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
          />
          <small className="form-note">Please use the email associated with your account.</small>
        </div>

        <div className="form-group">
          <label htmlFor="hearAbout">How did you hear about Find My Trainer? *</label>
          <input
            type="text"
            id="hearAbout"
            name="hearAbout"
            value={formData.hearAbout}
            onChange={handleChange}
            placeholder="e.g., Instagram, Google, Friend"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="sex">Select your sex. *</label>
          <select id="sex" name="sex" value={formData.sex} onChange={handleChange} required>
            <option value="">Select an option</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="dateOfBirth">What is your date of birth? *</label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            placeholder="dd/mm/yyyy"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="contactNumber">What is your contact number? *</label>
          <input
            type="tel"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            placeholder="Enter your contact number"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="location">Where are you based? *</label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleChange}
            placeholder="Enter your location"
            required
          />
          <small className="form-note">
            This will allow clients to filter by location.
          </small>
        </div>

        <div className="form-group">
          <label htmlFor="gymAddress">What is the address of the gym where you're based? *</label>
          <input
            type="text"
            id="gymAddress"
            name="gymAddress"
            value={formData.gymAddress}
            onChange={handleChange}
            placeholder="Enter the gym address"
            required
          />
          <small className="form-note">Please use the single-line address as it appears on Google. If you do not work from a gym, please enter your city.</small>
        </div>

        <div className="form-group">
          <label htmlFor="experienceYears">How long have you been a personal trainer? *</label>
          <select id="experienceYears" name="experienceYears" value={formData.experienceYears} onChange={handleChange} required>
            <option value="">Select an option</option>
            {experienceOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="chargePerSession">How much do you charge per session? *</label>
          <input
            type="text"
            id="chargePerSession"
            name="chargePerSession"
            value={formData.chargePerSession}
            onChange={handleChange}
            placeholder="Enter amount"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="chargePerBlock">How much do you charge per block? *</label>
          <input
            type="text"
            id="chargePerBlock"
            name="chargePerBlock"
            value={formData.chargePerBlock}
            onChange={handleChange}
            placeholder="Enter amount"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="sessionsPerBlock">How many sessions are in a block? *</label>
          <input
            type="number"
            id="sessionsPerBlock"
            name="sessionsPerBlock"
            value={formData.sessionsPerBlock}
            onChange={handleChange}
            placeholder="Enter number of sessions"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="onlineCoaching">Do you offer online coaching? *</label>
          <select id="onlineCoaching" name="onlineCoaching" value={formData.onlineCoaching} onChange={handleChange} required>
            <option value="">Select an option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="form-group">
          <label>Which areas of expertise do you specialize in? *</label>
          <div className="checkbox-group">
            {expertiseOptions.map((option, index) => (
              <label key={index} className="checkbox-label">
                <input
                  type="checkbox"
                  name="expertise"
                  value={option}
                  checked={formData.expertise.includes(option)}
                  onChange={handleChange}
                />
                {option}
              </label>
            ))}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="instagramHandle">Instagram Handle (if any)</label>
          <input
            type="text"
            id="instagramHandle"
            name="instagramHandle"
            value={formData.instagramHandle}
            onChange={handleChange}
            placeholder="Enter Instagram handle"
          />
        </div>

        <div className="form-group">
          <label htmlFor="about">Tell us about yourself. *</label>
          <textarea
            id="about"
            name="about"
            value={formData.about}
            onChange={handleChange}
            placeholder="Share a brief bio"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="profilePhoto">Upload a profile photo. *</label>
          <input
            type="file"
            id="profilePhoto"
            name="profilePhoto"
            accept="image/*"
            onChange={handleFileChange}
            required
          />
          <small className="form-note">Please upload a clear photo of yourself. Max size 2MB.</small>
        </div>

        <button type="submit" className="submit-button">Submit Application</button>
      </form>

      <Footer/>
    </div>
  );
};

export default CreateApplicationForm;
