import React, { useState } from 'react';
import TrainerSignUpModal from '../components/TrainerSignUpModal';

import './TrainerRegister.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TrainerRegister = () => {
  const [modalShow, setModalShow] = useState(false);

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  return (
    <div>
    <Header/>

    <div class="et_builder_inner_content1 et_pb_gutters3">
        <div class="et_pb_section1 et_pb_section_0 et_pb_with_background et_section_regular">
          <div class="et_pb_row1 et_pb_row_0">
            <div class="et_pb_column1 et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">
              <div class="et_pb_module et_pb_heading et_pb_heading_0 et_pb_bg_layout_">
                <div class="et_pb_heading_container"><h1 class="et_pb_module_heading">Register as a personal trainer</h1></div>
			        </div>
              <div class="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_center et_pb_bg_layout_light">
                <div class="et_pb_text_inner1">
                  <div class="page-title-section1">
                    <div class="page-header1 w-container">
                      <p class="page-subtitle1">Register as a personal trainer and join our community now!</p>
                    </div>
                  </div>
                </div>
			        </div>
			      </div>
			    </div>
				</div>
        <div class="et_pb_section1 et_pb_section_1 et_pb_with_background et_section_regular">
          <div class="et_pb_row1 et_pb_row_1 et_hover_enabled">
            <div class="et_pb_column1 et_pb_column_4_4 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough et-last-child">
              <div class="et_pb_module1 et_pb_text et_pb_text_11 et_pb_text_align_left et_pb_bg_layout_light et_had_animation" >
                <div class="et_pb_text_inner1"><h3>Register</h3></div>
              </div>
              <div class="et_pb_module1 et_pb_text et_pb_text_21  et_pb_text_align_left et_pb_bg_layout_light">
                <div class="et_pb_text_inner"><h2 class="common-subheading">Why register as a personal trainer</h2></div>
			        </div>
			      </div>
		      </div>
          <div class="et_pb_row1 et_pb_row_2 et_pb_row_3-4_1-4">
				    <div class="et_pb_column1 et_pb_column_3_41 et_pb_column_21  et_pb_css_mix_blend_mode_passthrough">
              <div class="et_pb_module1 et_pb_text et_pb_text_31  et_pb_text_align_left et_pb_bg_layout_light">
				        <div class="et_pb_text_inner"><p><span>Register as a personal trainer and join our community now!</span></p></div>
			        </div>
			      </div>
            <div class="et_pb_column1 et_pb_column_1_41 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough et-last-child">
				      <div class="et_pb_button_module_wrapper et_pb_button_0_wrapper et_pb_button_alignment_right et_pb_module ">
              <div class="cta-button-block">
                  <button className="btn1 btn-primary" onClick={handleModalShow}>
                    Register As A Trainer
                  </button>
                  <TrainerSignUpModal show={modalShow} handleClose={handleModalClose} />
                  </div>
			        </div>
			      </div>
				  </div>
          <div class="et_pb_row1 et_pb_row_3 et_pb_equal_columns et_pb_gutters2">
            <div class="et_pb_column1 et_pb_column_1_31 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough">
              <div class="et_pb_module1 et_pb_blurb et_pb_blurb_0  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
                <div class="et_pb_blurb_content">
                  <div class="et_pb_blurb_container">
						        <h4 class="et_pb_module_header"><span>Reach a wider audience</span></h4>
						        <div class="et_pb_blurb_description">
                      <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669b00-b0669af8" class="walkthrough-wrapper">
                        <div class="walkthrough-blurb"><span>Find My Trainer gives personal trainers access to a larger market – not limited to social media or word of mouth.</span></div>
                      </div>
                    </div>
					        </div>
				        </div>
			        </div>
			      </div>
            <div class="et_pb_column1 et_pb_column_1_31 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough">
              <div class="et_pb_module et_pb_blurb et_pb_blurb_1  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
                <div class="et_pb_blurb_content">
                  <div class="et_pb_blurb_container">
                    <h4 class="et_pb_module_header"><span>Showcase your skills &amp; experience</span></h4>
                    <div class="et_pb_blurb_description">
                      <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669b00-b0669af8" class="walkthrough-wrapper">
                        <div class="walkthrough-blurb">
                          <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669b08-b0669af8" class="walkthrough-wrapper">
                            <div class="walkthrough-blurb"><span>Create a profile and give clients access to all the need-to-know information about the service you offer.</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
					        </div>
				        </div>
			        </div>
			      </div>
            <div class="et_pb_column1 et_pb_column_1_31 et_pb_column_6  et_pb_css_mix_blend_mode_passthrough et-last-child">
				      <div class="et_pb_module et_pb_blurb et_pb_blurb_2  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
                <div class="et_pb_blurb_content">
                  <div class="et_pb_blurb_container">
                    <h4 class="et_pb_module_header"><span>Eliminate empty leads</span></h4>
                    <div class="et_pb_blurb_description">
                      <div id="w-node-d73c1651-c640-cfdb-fb0c-d9b0b0669b00-b0669af8" class="walkthrough-wrapper">
                        <div class="walkthrough-blurb"><span>Your profile will give clients the ability to make a decision on whether you are the right PT before they reach out.</span>
                        </div>
                      </div>
                    </div>
					        </div>
				        </div>
			        </div>
			      </div>
				  </div>
          <div class="et_pb_row1 et_pb_row_4">
				    <div class="et_pb_column et_pb_column_4_4 et_pb_column_7  et_pb_css_mix_blend_mode_passthrough et-last-child">
              <div class="et_pb_module et_pb_text et_pb_text_4 et_pb_text_align_left et_pb_bg_layout_light et_had_animation">
                <div class="et_pb_text_inner"><h3>Register</h3></div>
		          </div>
              <div class="et_pb_module et_pb_text et_pb_text_5  et_pb_text_align_left et_pb_bg_layout_light">
			          <div class="et_pb_text_inner"><h2 class="common-subheading">How to register as a personal trainer</h2></div>
			        </div>
              <div class="et_pb_module et_pb_text et_pb_text_6  et_pb_text_align_left et_pb_bg_layout_light">
			          <div class="et_pb_text_inner"><div class="trainer-register-text1 instructions-text1"><strong>1.<span>&nbsp;</span></strong>Create an account by clicking the ‘Register’ button below.<br/><strong>2.<span>&nbsp;</span></strong>Click the ‘Create application’ button to complete the registration form and populate your profile.<br/><strong>3.<span>&nbsp;</span></strong>Click ‘Upload documents’ in the My Profile tab to upload proof that you are a certified PT.<br/>Once your application has been accepted, you will receive an email notifying you know that your account is live.</div>
                  
                </div>
			        </div>
              <div class="et_pb_button_module_wrapper et_pb_button_1_wrapper et_pb_button_alignment_center et_pb_module ">
                <div class="cta-button-block">
                  <button className="btn2 btn-primary" onClick={handleModalShow}>
                    Register As A Trainer
                  </button>
                  <TrainerSignUpModal show={modalShow} handleClose={handleModalClose} />
                  </div>
			          </div>
			        </div>
			      </div>
				  </div>
    </div>
    
    <Footer/>
  </div>

    
  );
};

export default TrainerRegister;
