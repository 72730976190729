import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';

const TrainerLoginOverlay = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Hook to access navigate function

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post('http://localhost:5000/api/trainers/login', { email, password });
      // Save token to localStorage or handle it as needed
      localStorage.setItem('token', response.data.token);
      // Handle successful login, e.g., redirect to dashboard
      navigate('/trainer-dashboard'); // Redirect to dashboard
      onClose(); // Close the overlay
    } catch (err) {
      // Handle errors (e.g., invalid email/password)
      setError(err.response?.data || 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="msOverlay" className="overlay">
      <div className="ms-modal" id="LoginModal">
        <div className="ms-modal__close">
          <button onClick={onClose}>
            <svg fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" style={{ width: '100%' }}>
              <path fill="currentColor" shapeRendering="geometricPrecision" d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"></path>
            </svg>
          </button>
        </div>
        <div className="ms-modal__content">
          <figure className="ms-modal__figure">
            <img src="https://ms-application-assets.s3.amazonaws.com/images/app_cle9vd3jn004w0ukfa0594co8/528789-logo.png" alt="FirstRep" />
          </figure>
          <h2 className="ms-modal__title">Login to your account</h2>
          <form className="ms-form" onSubmit={handleLogin}>
            <div className="ms-form__group">
              <label className="ms-form__label" htmlFor="email">Email Address</label>
              <input
                className="ms-form__input"
                id="email"
                type="email"
                placeholder="example@domain.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="ms-form__group">
              <div className="ms-form__flex">
                <label className="ms-form__label" htmlFor="password">Password</label>
                <div className="ms-form__label ms-form__label--right">
                  <button className="ms-form__button ms-form__button--text" type="button">Forgot Password?</button>
                </div>
              </div>
              <div className="ms-form__password-container">
                <input
                  className="ms-form__input ms-form__input--password"
                  id="password"
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="ms-form__show-toggle">
                  <svg className="ms-form__eye" fill="none" viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" d="m11 0.5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path>
                  </svg>
                </div>
              </div>
            </div>
            {error && <p className="error">{error}</p>}
            <div>
              <button className="ms-form__button" type="submit" style={{ backgroundColor: 'rgb(27, 109, 232)' }}>
                {loading ? 'Logging in...' : 'Log in'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TrainerLoginOverlay;
