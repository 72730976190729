import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Make sure axios is installed
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import TrainerLoginOverlay from '../components/TrainerLoginOverlay';
import './Home.css'
import Header from '../components/Header';
import Footer from '../components/Footer';

const PAGE_SIZE = 6; // Number of trainers per page

const Home = () => {
  const [trainers, setTrainers] = useState([]);
  const [filteredTrainers, setFilteredTrainers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState('');
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [selectedSex, setSelectedSex] = useState('');
  const [selectedOnline, setSelectedOnline] = useState('');

  useEffect(() => {
    // Fetch trainers from the backend
    const fetchTrainers = async () => {
      try {
        const response = await axios.get('https://server.appertaxtech.co.zw/api/trainers'); // Adjust the URL if necessary
        const approvedTrainers = response.data.filter(trainer => trainer.approved === 1);
        setTrainers(approvedTrainers);
      } catch (error) {
        console.error('Error fetching trainers:', error);
      }
    };

    fetchTrainers();
  }, []);

  useEffect(() => {
    // Filtering logic based on selected filters
    const filtered = trainers.filter(trainer => {
      return (
        (trainer.first_name.toLowerCase().includes(searchName.toLowerCase()) ||
         trainer.last_name.toLowerCase().includes(searchName.toLowerCase())) &&
        (selectedLocation.length === 0 || selectedLocation.includes(trainer.location)) &&
        (selectedExperience.length === 0 || selectedExperience.includes(trainer.experience_years)) &&
        (selectedSex === '' || trainer.sex === selectedSex) &&
        (selectedOnline === '' || trainer.online_coaching === selectedOnline)
      );
    });

    setFilteredTrainers(filtered);
    setCurrentPage(1); // Reset to the first page when filters change
  }, [searchName, selectedLocation, selectedExperience, selectedSex, selectedOnline, trainers]);

  const paginatedTrainers = filteredTrainers.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
  const totalPages = Math.ceil(filteredTrainers.length / PAGE_SIZE);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  
  return (
    <div>
		<Header/>

      <div class='et_builder_inner_content et_pb_gutters3'>
      <div class="et_pb_section3 et_pb_section_01 et_pb_with_background et_section_regular">
			  <div class="et_pb_row3 et_pb_row_03">
				  <div class="et_pb_column3 et_pb_column_3_53 et_pb_column_03  et_pb_css_mix_blend_mode_passthrough3">
			      <div class="et_pb_module3 et_pb_blurb et_pb_blurb_0  et_pb_text_align_left  et_pb_blurb_position_left et_pb_bg_layout_light">
		          <div class="et_pb_blurb_content3">
					      <div class="et_pb_main_blurb_image3"><span class="et_pb_image_wrap3 et_pb_only_image_mode_wrap"><img fetchpriority="high" decoding="async" width="343" height="343" src="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/logo-mjwellness1.jpg" alt="" srcset="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/logo-mjwellness1.jpg 343w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/logo-mjwellness1-300x300.jpg 300w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/logo-mjwellness1-150x150.jpg 150w" sizes="(max-width: 343px) 100vw, 343px" class="et-waypoint et_pb_animation_top et_pb_animation_top_tablet et_pb_animation_top_phone wp-image-38 et-animated"/></span></div>
					        <div class="et_pb_blurb_container3">
					          <div class="et_pb_blurb_description3"><p class='welcome'>Welcome To</p></div>
					        </div>
				        </div>
			        </div>
              <div class="et_pb_module3 et_pb_heading3 et_pb_heading_03 et_pb_bg_layout_">
				        <div class="et_pb_heading_container3"><h1 class="et_pb_module_heading3">Find My Trainer</h1></div>
			        </div>
              <div class="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light">
				        <div class="et_pb_text_inner"><div class="flex max-w-full flex-col flex-grow">
                  <div data-message-author-role="assistant" data-message-id="0baa751d-7f61-4cc0-858c-5da45587f395" dir="auto" class="min-h-[20px] text-message flex w-full flex-col items-end gap-2 whitespace-pre-wrap break-words [.text-message+&amp;]:mt-5 overflow-x-auto">
                    <div class="flex w-full flex-col gap-1 empty:hidden first:pt-[3px]">
                      <div class="markdown prose w-full break-words dark:prose-invert dark">
                        <p class='welcome_text'>Welcome to Find My Trainer, your trusted platform for finding certified personal trainers to help you achieve your fitness goals. <strong>Discover the perfect trainer and start your journey today!</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
			      </div>
			    </div>
          <div class="et_pb_column et_pb_column_2_5 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough et-last-child et_pb_column_empty">
				</div>
			</div>
      <div class="et_pb_row3 et_pb_row_13 et_pb_equal_columns3 et_pb_gutters2">
				<div class="et_pb_column3 et_pb_column_1_33 et_pb_column_23  et_pb_css_mix_blend_mode_passthrough et_pb_column_empty">
			  </div>
        <div class="et_pb_column3 et_pb_column_1_33 et_pb_column_33  et_pb_css_mix_blend_mode_passthrough">
			    <div class="et_pb_module3 et_pb_heading et_pb_heading_1 et_pb_bg_layout_">
			      <div class="et_pb_heading_container"><h1 class="et_pb_module_heading">Connect With New Clients</h1></div>
			    </div>
          <div class="et_pb_module3 et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">
				    <div class="et_pb_text_inner"><div class="content-text_h">Create a profile to authentically showcase your unique qualities and stand out!</div></div>
			    </div>
          <div class="et_pb_button_module_wrapper et_pb_button_0_wrapper  et_pb_module ">
				    <a class="et_pb_button et_pb_button_0 et_pb_bg_layout_light" href="/trainer-register">Register As A Trainer</a>
			    </div>
			  </div>
        <div class="et_pb_column3 et_pb_column_1_33 et_pb_column_43  et_pb_css_mix_blend_mode_passthrough et-last-child">
				  <div class="et_pb_module et_pb_heading et_pb_heading_2 et_pb_bg_layout_">
				    <div class="et_pb_heading_container"><h1 class="et_pb_module_heading">Find The Right Personal Trainer</h1></div>
			    </div>
          <div class="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">
				    <div class="et_pb_text_inner"><div class="content-text_h">Compare the best personal trainers around Zimbabwe and reach out to them directly!</div></div>
			    </div>
          <div class="et_pb_button_module_wrapper et_pb_button_1_wrapper  et_pb_module ">
				    <a class="et_pb_button et_pb_button_1 et_pb_bg_layout_light" href="/browse">Browse Personal Trainers</a>
			    </div>
			  </div>
			</div>
		</div>
      </div>

      <div class="et_pb_section3 et_pb_section_1 et_pb_with_background et_section_regular">
			<div class="et_pb_row3 et_pb_row_2">
				<div class="et_pb_column et_pb_column_4_43 et_pb_column_53  et_pb_css_mix_blend_mode_passthrough et-last-child">
			    <div class="et_pb_module et_pb_text et_pb_text_3 et_pb_text_align_left et_pb_bg_layout_light et_had_animation">
				    <div class="et_pb_text_inner"><h3>Browse Trainers</h3></div>
					<div class="et_pb_module et_pb_blurb et_pb_blurb_0_tb_footer  et_pb_text_align_left  et_pb_blurb_position_left et_pb_bg_layout_light">
                        </div>
          </div>
          <div class="et_pb_module et_pb_text et_pb_text_4  et_pb_text_align_left et_pb_bg_layout_light">
				    <div class="et_pb_text_inner"><h2 class='heading_inner'>Highly Qualified Trainers</h2></div>
			    </div>
          <div class="et_pb_module et_pb_text et_pb_text_5  et_pb_text_align_left et_pb_bg_layout_light">
				    <div class="et_pb_text_inner"><p class='all_text'>Choose from our curated selection of highly qualified trainers, each with proven expertise. Whether it’s strength training, weight loss, or specialized coaching, find the perfect match to achieve your fitness goals.</p></div>
			    </div>
			  </div>
			</div>
      <div class="et_pb_row3 et_pb_row_3">
      <div className="trainer-content">
      <div className="trainer-grid">
        {paginatedTrainers.map(trainer => (
          <div key={trainer.id} className="trainer-card">
            <div className="trainer-image">
              <img src={`http://localhost:5000/${trainer.profile_photo}`} alt={trainer.first_name + ' ' + trainer.last_name} />
            </div>
            <div className="trainer-details">
              <div className="trainer-name">{trainer.first_name} {trainer.last_name}</div>
              <div className="trainer-info">
                <div className="trainer-info-item">Location: {trainer.location}</div>
                <div className="trainer-info-item">Rate: ${trainer.charge_per_session} per session</div>
              </div>
              <Link to={`/trainers/${trainer.id}`} className="view-profile-btn">View Profile</Link>
            </div>
          </div>
        ))}
      </div>
      
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          Prev
        </button>
        <span className="pagination-info">Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      </div>
      </div>
      </div>

      <div class="et_pb_row et_pb_row_45">
				<div class="et_pb_column et_pb_column_4_4 et_pb_column_10  et_pb_css_mix_blend_mode_passthrough et-last-child">
				
				<div class="et_pb_button_module_wrapper et_pb_button_2_wrapper et_pb_button_alignment_center et_pb_module ">
				<a class="et_pb_button et_pb_button_2 et_pb_bg_layout_light" href="">Browse All Personal Trainers</a>
			</div>
			</div>
				
				
				
				
			</div>
				
				
		  </div>

      {/*<div class="et_pb_section et_pb_section_2 et_section_regular">
        <div class="et_pb_row3 et_pb_row_53 et_pb_gutters2">
				  <div class="et_pb_column3 et_pb_column_2_5 et_pb_column_11  et_pb_css_mix_blend_mode_passthrough">
            <div class="et_pb_module et_pb_text et_pb_text_6 et_pb_text_align_left et_pb_bg_layout_light et_had_animation" >
              <div class="et_pb_text_inner"><h3>About Us</h3></div>
			      </div>
          <div class="et_pb_module et_pb_text et_pb_text_7  et_pb_text_align_left et_pb_bg_layout_light">
		        <div class="et_pb_text_inner"><h2>What is Find My Trainer</h2></div>
			    </div>
				<div class="et_pb_module et_pb_image et_pb_image_0">
				    <span class="et_pb_image_wrap "><img decoding="async" width="1080" height="714" src="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-02.jpg" alt="" title="personal-trainer-02" srcset="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-02.jpg 1080w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-02-980x648.jpg 980w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-02-480x317.jpg 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1080px, 100vw" class="wp-image-20"/></span>
			    </div>
			  </div>
			  
        <div class="et_pb_column et_pb_column_3_5 et_pb_column_12  et_pb_css_mix_blend_mode_passthrough et-last-child">
          <div class="et_pb_module et_pb_text et_pb_text_8  et_pb_text_align_left et_pb_bg_layout_light">
		        <div class="et_pb_text_inner"><p>Find My Trainer is an online platform designed to effortlessly connect personal trainers with clients all across the country. Whether you're a fitness enthusiast looking to take your workouts to the next level or someone just starting their fitness journey, our platform offers a convenient and efficient way to find the right trainer for your needs. We bring together a diverse range of qualified professionals, making it easy for clients to access personalized training, no matter where they are located. We provide:&nbsp;</p>
              <ul class='home_ul'>
                <li>Enhanced visibility for trainers, allowing you to showcase your expertise, services, and unique approach to fitness. Clients can easily see why you’re the perfect fit to help them reach their goals.</li>
              </ul>
              <ul class='home_ul'>
                <li>A user-friendly catalogue that lets individuals browse through a wide selection of top personal trainers. Whether you’re looking for specialized training or general fitness guidance, you can find the trainer that aligns with your specific needs and aspirations.</li>
              </ul>
            </div>
			    </div>
          <div class="et_pb_button_module_wrapper et_pb_button_3_wrapper  et_pb_module ">
				    <a class="et_pb_button et_pb_button_3 et_pb_bg_layout_light" href="">Know More</a>
			    </div>
			  </div>
			</div>
      <div class="et_pb_row3 et_pb_row_63">
				<div class="et_pb_column3 et_pb_column_1_23 et_pb_column_13  et_pb_css_mix_blend_mode_passthrough">
			    <div class="et_pb_module et_pb_image et_pb_image_0">
				    <span class="et_pb_image_wrap "><img decoding="async" width="1080" height="714" src="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-02.jpg" alt="" title="personal-trainer-02" srcset="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-02.jpg 1080w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-02-980x648.jpg 980w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-02-480x317.jpg 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1080px, 100vw" class="wp-image-20"/></span>
			    </div>
			  </div>
        <div class="et_pb_column et_pb_column_1_2 et_pb_column_14  et_pb_css_mix_blend_mode_passthrough et-last-child">
				  <div class="et_pb_module et_pb_text et_pb_text_9 et_pb_text_align_left et_pb_bg_layout_light et_had_animation">
				    <div class="et_pb_text_inner"><h3>Subscribe</h3></div>
			    </div>
          <div class="et_pb_module et_pb_divider et_pb_divider_2 et_pb_divider_position_ et_pb_space et_had_animation">
            <div class="et_pb_divider_internal"></div>
          </div>
          <div class="et_pb_module et_pb_text et_pb_text_10  et_pb_text_align_left et_pb_bg_layout_light">
				    <div class="et_pb_text_inner"><h2>Join Mailing list</h2></div>
			    </div>
          <div class="et_pb_module et_pb_text et_pb_text_11  et_pb_text_align_left et_pb_bg_layout_light">
				    <div class="et_pb_text_inner"><p><span>Don’t miss out! Sign-up and be the first to know of any updates.</span></p></div>
			    </div>
          <div class="et_pb_with_border et_pb_module et_pb_signup_0 et_pb_newsletter_layout_bottom_top et_pb_newsletter et_pb_subscribe clearfix  et_pb_text_align_left et_pb_bg_layout_dark et_pb_newsletter_description_no_title et_pb_newsletter_description_no_content">
				    <div class="et_pb_newsletter_description et_multi_view_hidden"></div>
			    </div>
			  </div>
			</div>
		  </div>*/}

		  <div class="et_pb_section33 et_pb_section_33 et_section_specialty">
				
				
				
				
				
				<div class="et_pb_row78">
				<div class="et_pb_column et_pb_column_1_3 et_pb_column_15 et_pb_css_mix_blend_mode_passthrough et_pb_column_single">
				
				
				
				
				<div class="et_pb_module et_pb_image et_pb_image_110">
				
				
				
				
				<span class="et_pb_image_wrap "><img loading="lazy" decoding="async" width="1333" height="1777" src="/images/findmytrainer1.jpg" alt="" title="findmytrainer1"  sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) and (max-width: 1280px) 1280px, (min-width: 1281px) 1333px, 100vw" class="wp-image-132"/></span>
			</div>
			</div><div class="et_pb_column et_pb_column_2_3 et_pb_column_16   et_pb_specialty_column  et_pb_css_mix_blend_mode_passthrough et-last-child">
				
				
				
				
				<div class="et_pb_row_inner et_pb_row_inner_010">
				<div class="et_pb_column et_pb_column_4_4 et_pb_column_inner et_pb_column_inner_0 et-last-child">
				
				
				
				
				<div class="et_pb_module et_pb_text et_pb_text_12 et_pb_text_align_left et_pb_bg_layout_light et_had_animation" >
				
				
				
				
				<div class="et_pb_text_inner"><h3>About Us</h3></div>
			</div><div class="et_pb_module et_pb_text et_pb_text_13  et_pb_text_align_left et_pb_bg_layout_light">
				
				
				
				
				<div class="et_pb_text_inner"><h2>What is Find MY Trainer</h2></div>
			</div><div class="et_pb_module et_pb_text et_pb_text_14  et_pb_text_align_left et_pb_bg_layout_light">
				
				
				
				
				<div class="et_pb_text_inner"><p class='whatis-paragraph'>Find My Trainer is an online platform designed to effortlessly connect personal trainers with clients all across the country. Whether you’re a fitness enthusiast looking to take your workouts to the next level or someone just starting their fitness journey, our platform offers a convenient and efficient way to find the right trainer for your needs. We bring together a diverse range of qualified professionals, making it easy for clients to access personalized training, no matter where they are located. We provide:&nbsp;</p>

</div>
			</div>
			</div>
				
				
				
				
			</div><div class="et_pb_row_inner et_pb_row_inner_1 et_pb_equal_columns et_pb_gutters2">
				<div class="et_pb_column et_pb_column_1_3 et_pb_column_inner et_pb_column_inner_1">
				
				
				
				
				<div class="et_pb_module et_pb_blurb et_pb_blurb_5  et_pb_text_align_left  et_pb_blurb_position_left et_pb_bg_layout_light">
				
				<div class="et_pb_blurb_content35">
					<div class="et_pb_main_blurb_image350"><span class="et_pb_image_wrap3 et_pb_only_image_mode_wrap"><img fetchpriority="high" decoding="async" width="343" height="343" src="/images/arrow.png" alt=""  sizes="(max-width: 343px) 100vw, 343px" class="et-waypoint et_pb_animation_top et_pb_animation_top_tablet et_pb_animation_top_phone wp-image-38 et-animated"/></span></div>
					<div class="et_pb_blurb_container35">
						<div class="et_pb_blurb_description3"><p class='choose-us-text'>Increase your visibility and attract more clients by showcasing your expertise, services, and unique approach to fitness. With enhanced visibility on our platform, potential clients can easily discover what sets you apart and why you’re the ideal trainer to help them achieve their goals.</p></div>
					</div>
				</div>
		
			</div>
			</div><div class="et_pb_column et_pb_column_1_3 et_pb_column_inner et_pb_column_inner_2 et-last-child">
				
				
				
				
				<div class="et_pb_module et_pb_blurb et_pb_blurb_6  et_pb_text_align_left  et_pb_blurb_position_left et_pb_bg_layout_light">
				
				<div class="et_pb_blurb_content35">
					<div class="et_pb_main_blurb_image350"><span class="et_pb_image_wrap3 et_pb_only_image_mode_wrap"><img fetchpriority="high" decoding="async" width="343" height="343" src="/images/arrow.png" alt=""  sizes="(max-width: 343px) 100vw, 343px" class="et-waypoint et_pb_animation_top et_pb_animation_top_tablet et_pb_animation_top_phone wp-image-38 et-animated"/></span></div>
					<div class="et_pb_blurb_container35">
						<div class="et_pb_blurb_description3"><p class='choose-us-text'>A user-friendly catalogue that lets individuals browse through a wide selection of top personal trainers. Whether you’re looking for specialized training or general fitness guidance, you can find the trainer that aligns with your specific needs and aspirations.</p></div>
					</div>
				</div>
				
				
				
			</div>
			</div>
				
				
				
				
			</div>
			</div>
				</div>
				
			</div>

			<div class="et_pb_section5 et_pb_section_4 et_pb_with_background et_section_regular">
				
				
				
				<span class="et_pb_background_pattern"></span>
				
				
				<div class="et_pb_row et_pb_row_7">
				<div class="et_pb_column5 et_pb_column_1_35 et_pb_column_17  et_pb_css_mix_blend_mode_passthrough">
				
				
				
				
				<div class="et_pb_module et_pb_text et_pb_text_15 et_pb_text_align_left et_pb_bg_layout_light et_had_animation" >
				
				
				
				
				<div class="et_pb_text_inner"><h3>News &amp; Insights</h3></div>
			</div><div class="et_pb_module et_pb_divider et_pb_divider_4 et_pb_divider_position_ et_pb_space et_had_animation" ><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_text et_pb_text_16  et_pb_text_align_left et_pb_bg_layout_light">
				
				
				
				
				<div class="et_pb_text_inner"><h2>Read Our Latest Blog</h2></div>
			</div><div class="et_pb_module et_pb_text et_pb_text_17  et_pb_text_align_left et_pb_bg_layout_light">
				
				
				
				
				<div class="et_pb_text_inner"><p><span>Sign up now and be the first to receive updates, tips, and exclusive content from our experts. Don't miss out on valuable insights and news tailored just for you!</span></p></div>
			</div>
			</div><div class="et_pb_column5 et_pb_column_1_35 et_pb_column_18  et_pb_css_mix_blend_mode_passthrough">
				
				
				
				
				<div class="et_pb_module et_pb_blog_4 et_pb_posts et_pb_bg_layout_light ">
				
				
				
				
				<div class="et_pb_ajax_pagination_container">
					
			<article id="post-120" class="et_pb_post clearfix et_pb_blog_item_4_0 post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-training">

				<a href="https://appertax.co.zw/findmytrainer/2024/08/21/unlocking-your-full-potential-how-fitness-training-empowers-every-aspect-of-your-life/" class="entry-featured-image-url"><img loading="lazy" decoding="async" src="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/21924-1080x675.jpg" alt="Unlocking Your Full Potential: How Fitness Training Empowers Every Aspect of Your Life" class="" width="1080" height="675" srcset="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/21924-980x653.jpg 980w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/21924-480x320.jpg 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1080px, 100vw"/></a>
														<h2 class="entry-title">
													<a class='entry-title-a' href="https://appertax.co.zw/findmytrainer/2024/08/21/unlocking-your-full-potential-how-fitness-training-empowers-every-aspect-of-your-life/">Unlocking Your Full Potential: How Fitness Training Empowers Every Aspect of Your Life</a>
											</h2>
				
					<p class="post-meta">by <span class="author vcard"><a href="https://appertax.co.zw/findmytrainer/author/admin/" title="Posts by admin" rel="author">admin</a></span> | <span class="published">Aug 21, 2024</span> | <a href="https://appertax.co.zw/findmytrainer/category/training/" rel="tag">Training</a></p><div class="post-content"><div class="post-content-inner"><p>Fitness training is often associated with physical transformation, but its impact goes far beyond...</p>
</div></div>			
			</article>
				
				</div>
				</div> 
			</div><div class="et_pb_column5 et_pb_column_1_35 et_pb_column_19  et_pb_css_mix_blend_mode_passthrough et-last-child">
				
				
				
				
				<div class="et_pb_module et_pb_blog_5 et_pb_posts et_pb_bg_layout_light ">
				
				
				
				
				<div class="et_pb_ajax_pagination_container">
					
			<article id="post-113" class="et_pb_post clearfix et_pb_blog_item_5_0 post-113 post type-post status-publish format-standard has-post-thumbnail hentry category-training">

				<a href="https://appertax.co.zw/findmytrainer/2024/08/21/the-comprehensive-benefits-of-fitness-training-why-its-more-than-just-exercise/" class="entry-featured-image-url"><img loading="lazy" decoding="async" src="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/2149734681-1080x675.jpg" alt="The Comprehensive Benefits of Fitness Training: Why It’s More Than Just Exercise" class="" width="1080" height="675" srcset="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/2149734681-980x653.jpg 980w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/2149734681-480x320.jpg 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1080px, 100vw"/></a>
														<h2 class="entry-title">
													<a class='entry-title-a' href="https://appertax.co.zw/findmytrainer/2024/08/21/the-comprehensive-benefits-of-fitness-training-why-its-more-than-just-exercise/">The Comprehensive Benefits of Fitness Training: Why It’s More Than Just Exercise</a>
											</h2>
				
					<p class="post-meta">by <span class="author vcard"><a href="https://appertax.co.zw/findmytrainer/author/admin/" title="Posts by admin" rel="author">admin</a></span> | <span class="published">Aug 21, 2024</span> | <a href="https://appertax.co.zw/findmytrainer/category/training/" rel="tag">Training</a></p><div class="post-content"><div class="post-content-inner"><p>When you think about fitness training, you might immediately picture a gym full of weights,...</p>
</div></div>			
			</article>
				
				</div>
				</div> 
			</div>
				
				
				
				
			</div><div class="et_pb_row et_pb_row_8">
				<div class="et_pb_column et_pb_column_1_3 et_pb_column_20  et_pb_css_mix_blend_mode_passthrough">
				
				
				
				
				<div class="et_pb_module et_pb_blog_6 et_pb_posts et_pb_bg_layout_light ">
				
				
				
				
				<div class="et_pb_ajax_pagination_container">
					
			<article id="post-109" class="et_pb_post clearfix et_pb_blog_item_6_0 post-109 post type-post status-publish format-standard has-post-thumbnail hentry category-training">

				<a href="https://appertax.co.zw/findmytrainer/2024/08/21/how-fitness-trainers-can-help-you-transform-your-body-and-mind/" class="entry-featured-image-url"><img loading="lazy" decoding="async" src="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/111016-1080x675.jpg" alt="How Fitness Trainers Can Help You Transform Your Body and Mind" class="" width="1080" height="675" srcset="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/111016-980x653.jpg 980w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/111016-480x320.jpg 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1080px, 100vw"/></a>
														<h2 class="entry-title">
													<a class='entry-title-a' href="https://appertax.co.zw/findmytrainer/2024/08/21/how-fitness-trainers-can-help-you-transform-your-body-and-mind/">How Fitness Trainers Can Help You Transform Your Body and Mind</a>
											</h2>
				
					<p class="post-meta">by <span class="author vcard"><a href="https://appertax.co.zw/findmytrainer/author/admin/" title="Posts by admin" rel="author">admin</a></span> | <span class="published">Aug 21, 2024</span> | <a href="https://appertax.co.zw/findmytrainer/category/training/" rel="tag">Training</a></p><div class="post-content"><div class="post-content-inner"><p>When most people think about fitness trainers, they often focus on the physical benefits: toned...</p>
</div></div>			
			</article>
				
				</div>
				</div> 
			</div><div class="et_pb_column et_pb_column_1_3 et_pb_column_21  et_pb_css_mix_blend_mode_passthrough">
				
				
				
				
				<div class="et_pb_module et_pb_blog_7 et_pb_posts et_pb_bg_layout_light ">
				
				
				
				
				<div class="et_pb_ajax_pagination_container">
					
			<article id="post-103" class="et_pb_post clearfix et_pb_blog_item_7_0 post-103 post type-post status-publish format-standard has-post-thumbnail hentry category-personal-trainer">

				<a href="https://appertax.co.zw/findmytrainer/2024/08/21/the-top-10-benefits-of-working-with-a-personal-trainer-to-reach-your-goals/" class="entry-featured-image-url"><img loading="lazy" decoding="async" src="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/2148419833-1080x675.jpg" alt="The Top 10 Benefits of Working with a Personal Trainer to Reach your Goals" class="" width="1080" height="675" srcset="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/2148419833-980x653.jpg 980w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/2148419833-480x320.jpg 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1080px, 100vw"/></a>
														<h2 class="entry-title">
													<a class='entry-title-a' href="https://appertax.co.zw/findmytrainer/2024/08/21/the-top-10-benefits-of-working-with-a-personal-trainer-to-reach-your-goals/">The Top 10 Benefits of Working with a Personal Trainer to Reach your Goals</a>
											</h2>
				
					<p class="post-meta">by <span class="author vcard"><a href="https://appertax.co.zw/findmytrainer/author/admin/" title="Posts by admin" rel="author">admin</a></span> | <span class="published">Aug 21, 2024</span> | <a href="https://appertax.co.zw/findmytrainer/category/personal-trainer/" rel="tag">Personal Trainer</a></p><div class="post-content"><div class="post-content-inner"><p>In a world where fitness apps and online workout videos are readily available, you might wonder if...</p>
</div></div>			
			</article>
				
				</div>
				</div> 
			</div><div class="et_pb_column et_pb_column_1_3 et_pb_column_22  et_pb_css_mix_blend_mode_passthrough et-last-child">
				
				
				
				
				<div class="et_pb_module et_pb_blog_8 et_pb_posts et_pb_bg_layout_light ">
				
				
				
				
				<div class="et_pb_ajax_pagination_container">
					
			<article id="post-97" class="et_pb_post clearfix et_pb_blog_item_8_0 post-97 post type-post status-publish format-standard has-post-thumbnail hentry category-fitness">

				<a href="https://appertax.co.zw/findmytrainer/2024/08/21/why-everyone-needs-a-fitness-trainer-the-ultimate-guide-to-achieving-your-goals/" class="entry-featured-image-url"><img loading="lazy" decoding="async" src="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/29683-1080x675.jpg" alt="Why Everyone Needs a Fitness Trainer: The Ultimate Guide to Achieving Your Goals" class="" width="1080" height="675" srcset="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/29683-980x653.jpg 980w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/29683-480x320.jpg 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1080px, 100vw"/></a>
														<h2 class="entry-title">
													<a class='entry-title-a' href="https://appertax.co.zw/findmytrainer/2024/08/21/why-everyone-needs-a-fitness-trainer-the-ultimate-guide-to-achieving-your-goals/">Why Everyone Needs a Fitness Trainer: The Ultimate Guide to Achieving Your Goals</a>
											</h2>
				
					<p class="post-meta">by <span class="author vcard"><a href="https://appertax.co.zw/findmytrainer/author/admin/" title="Posts by admin" rel="author">admin</a></span> | <span class="published">Aug 21, 2024</span> | <a href="https://appertax.co.zw/findmytrainer/category/fitness/" rel="tag">Fitness</a></p><div class="post-content"><div class="post-content-inner"><p>In today’s fast-paced world, maintaining a healthy lifestyle can be challenging. Between work,...</p>
</div></div>			
			</article>
				
				</div>
				</div> 
			</div>
				
				
				
				
			</div>
			<div class="et_pb_row et_pb_row_4">
				<div class="et_pb_column et_pb_column_4_4 et_pb_column_10  et_pb_css_mix_blend_mode_passthrough et-last-child">
					<div class="et_pb_button_module_wrapper et_pb_button_2_wrapper et_pb_button_alignment_center et_pb_module ">
						<a class="et_pb_button et_pb_button_2 et_pb_bg_layout_light" href="https://appertax.co.zw/findmytrainer/blog/">Read Our Blog</a>
					</div>
				</div>
			</div>
				
			</div>

			<div class="et_pb_section6 et_pb_section_5 et_section_regular">
				
				
				
				
				
				
				<div class="et_pb_row7 et_pb_row_10 et_hover_enabled">
				<div class="et_pb_column et_pb_column_2_5 et_pb_column_24  et_pb_css_mix_blend_mode_passthrough">
				
				
				
				
				<div class="et_pb_module et_pb_text et_pb_text_18 et_pb_text_align_left et_pb_bg_layout_light et_had_animation" >
				
				
				
				
				<div class="et_pb_text_inner"><h3>Choose Us</h3></div>
			</div><div class="et_pb_module et_pb_divider et_pb_divider_5 et_pb_divider_position_ et_pb_space"><div class="et_pb_divider_internal"></div></div><div class="et_pb_module et_pb_text et_pb_text_19  et_pb_text_align_left et_pb_bg_layout_light">
				
				
				
				
				<div class="et_pb_text_inner"><h2>Why choose Find My Trainer?</h2></div>
			</div><div class="et_pb_module et_pb_blurb et_pb_blurb_5 et_animated  et_pb_text_align_left  et_pb_blurb_position_left et_pb_bg_layout_dark" data-animation-style="foldLeft" data-animation-repeat="" data-animation-duration="1000ms" data-animation-delay="0ms" data-animation-intensity="5%" data-animation-starting-opacity="100%" data-animation-speed-curve="ease-in-out">
				
			
				
				<div class="et_pb_blurb_content35">
					<div class="et_pb_main_blurb_image35"><span class="et_pb_image_wrap3 et_pb_only_image_mode_wrap"><img fetchpriority="high" decoding="async" width="343" height="343" src="/images/check.png" alt=""  sizes="(max-width: 343px) 100vw, 343px" class="et-waypoint et_pb_animation_top et_pb_animation_top_tablet et_pb_animation_top_phone wp-image-38 et-animated"/></span></div>
					<div class="et_pb_blurb_container35">
						<div class="et_pb_blurb_description3"><p class='choose-us-text'>We remove the guesswork.</p></div>
					</div>
				</div>


			</div><div class="et_pb_module et_pb_blurb et_pb_blurb_6 et_animated  et_pb_text_align_left  et_pb_blurb_position_left et_pb_bg_layout_dark" data-animation-style="foldLeft" data-animation-repeat="" data-animation-duration="1000ms" data-animation-delay="0ms" data-animation-intensity="5%" data-animation-starting-opacity="100%" data-animation-speed-curve="ease-in-out">
				
				
			<div class="et_pb_blurb_content35">
					<div class="et_pb_main_blurb_image35"><span class="et_pb_image_wrap3 et_pb_only_image_mode_wrap"><img fetchpriority="high" decoding="async" width="343" height="343" src="/images/check.png" alt="" sizes="(max-width: 343px) 100vw, 343px" class="et-waypoint et_pb_animation_top et_pb_animation_top_tablet et_pb_animation_top_phone wp-image-38 et-animated"/></span></div>
					<div class="et_pb_blurb_container35">
						<div class="et_pb_blurb_description3"><p class='choose-us-text'>We only work with the best.</p></div>
					</div>
				</div>
				
				
			</div><div class="et_pb_module et_pb_blurb et_pb_blurb_7 et_animated  et_pb_text_align_left  et_pb_blurb_position_left et_pb_bg_layout_dark" data-animation-style="foldLeft" data-animation-repeat="" data-animation-duration="1000ms" data-animation-delay="0ms" data-animation-intensity="5%" data-animation-starting-opacity="100%" data-animation-speed-curve="ease-in-out">
				
				
			<div class="et_pb_blurb_content355">
					<div class="et_pb_main_blurb_image35"><span class="et_pb_image_wrap3 et_pb_only_image_mode_wrap"><img fetchpriority="high" decoding="async" width="343" height="343" src="/images/check.png" alt=""  sizes="(max-width: 343px) 100vw, 343px" class="et-waypoint et_pb_animation_top et_pb_animation_top_tablet et_pb_animation_top_phone wp-image-38 et-animated"/></span></div>
					<div class="et_pb_blurb_container35">
						<div class="et_pb_blurb_description3"><p class='choose-us-text'>We work with you, for you.</p></div>
					</div>
				</div>
				
				
			</div><div class="et_pb_button_module_wrapper et_pb_button_5_wrapper  et_pb_module ">
				<a class="et_pb_button et_pb_button_5 et_pb_bg_layout_light" href="/about">Find Out More</a>
			</div>
			</div><div class="et_pb_column et_pb_column_3_5 et_pb_column_25  et_pb_css_mix_blend_mode_passthrough et-last-child">
				
				
				
				
				<div class="et_pb_module et_pb_image et_pb_image_1 et_hover_enabled et-animated et_had_animation" >
				
				
				
				
				<span class="et_pb_image_wrap has-box-shadow-overlay"><div class="box-shadow-overlay"></div><img loading="lazy" decoding="async" width="1080" height="810" src="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-19.jpg" alt="" title="personal-trainer-19" srcset="https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-19.jpg 1080w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-19-980x735.jpg 980w, https://appertax.co.zw/findmytrainer/wp-content/uploads/2024/08/personal-trainer-19-480x360.jpg 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1080px, 100vw" class="wp-image-28"/></span>
			</div>
			</div>
				
				
				
				
			</div>
				
				
			</div>

      <div class="et_pb_section4 et_pb_section_2 et_section_regular">
				<div class="et_pb_row3 et_pb_row_5 et_pb_gutters2">
				    <div class="et_pb_column4 et_pb_column_4_44 et_pb_column_9  et_pb_css_mix_blend_mode_passthrough et-last-child">
				        <div class="et_pb_module et_pb_text et_pb_text_8 et_pb_text_align_left et_pb_bg_layout_light et_had_animation">
				            <div class="et_pb_text_inner"><h3>About Us</h3></div>
			            </div>
                        <div class="et_pb_module et_pb_text et_pb_text_9  et_pb_text_align_left et_pb_bg_layout_light">
				            <div class="et_pb_text_inner"><h2>How Find My Trainer Works</h2></div>
			            </div>
			        </div>
			    </div>
                <div class="et_pb_row4 et_pb_row_6 et_pb_equal_columns et_pb_gutters2">
				    <div class="et_pb_column4 et_pb_column_1_34 et_pb_column_104  et_pb_css_mix_blend_mode_passthrough">
				        <div class="et_pb_module et_pb_blurb et_pb_blurb_3  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
				            <div class="et_pb_blurb_content">
					            <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img src="/images/search.png" alt="Follow on Instagram" class="home_blurb_image"/></span></div>
					                <div class="et_pb_blurb_container">
						                <h4 class="et_pb_module_header"><span>Find a personal trainer</span></h4>
					                    </div>
										<div class="walkthrough-blurb">Find the ideal personal trainer by location, expertise, and reviews. Whether it’s strength training or weight loss, we’ll help you find the right fit.</div>
				                    </div>
									
			                    </div>
			                </div>
                            <div class="et_pb_column4 et_pb_column_1_34 et_pb_column_114  et_pb_css_mix_blend_mode_passthrough">
				                <div class="et_pb_module et_pb_blurb et_pb_blurb_4  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
				                    <div class="et_pb_blurb_content">
					                    <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img src="/images/calendar.png" alt="Follow on Instagram" class="home_blurb_image"/></span></div>
					                        <div class="et_pb_blurb_container">
						                        <h4 class="et_pb_module_header"><span>Book a session</span></h4>
					                        </div>
											<div class="walkthrough-blurb">Compare top trainers by qualifications, specialties, pricing, and client feedback. Easily find the trainer that aligns with your fitness goals and budget.</div>
				                        </div>
			                        </div>
			                    </div>
                                <div class="et_pb_column4 et_pb_column_1_34 et_pb_column_124  et_pb_css_mix_blend_mode_passthrough et-last-child">
				                    <div class="et_pb_module et_pb_blurb et_pb_blurb_5  et_pb_text_align_left  et_pb_blurb_position_top et_pb_bg_layout_light">
				                        <div class="et_pb_blurb_content">
					                        <div class="et_pb_main_blurb_image"><span class="et_pb_image_wrap"><img src="/images/check1.png" alt="Follow on Instagram" class="home_blurb_image"/></span></div>
					                            <div class="et_pb_blurb_container">
						                            <h4 class="et_pb_module_header"><span>Progress</span></h4>
					                            </div>
												<div class="walkthrough-blurb">Ready to start? Book a session with your chosen trainer through our easy enquiry form. Whether in-person or virtual, getting started has never been simpler.</div>
				                            </div>
			                            </div>
			                        </div>
			                    </div>
			</div>

		<Footer/>

    </div>
  );
};

export default Home;
